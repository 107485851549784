export const EXAMPLES = [
  {
    path: '/images/lesson-examples/example-1.webp',
  },
  {
    path: '/images/lesson-examples/example-9.webp',
  },
  {
    path: '/images/lesson-examples/example-2.webp',
  },
  {
    path: '/images/lesson-examples/example-3.webp',
  },
  {
    path: '/images/lesson-examples/example-5.webp',
  },
  {
    path: '/images/lesson-examples/example-6.webp',
  },
  {
    path: '/images/lesson-examples/example-7.webp',
  },
  {
    path: '/images/lesson-examples/example-8.webp',
  },
  {
    path: '/images/lesson-examples/roadmap-1.webp',
  },
  {
    path: '/images/lesson-examples/roadmap-2.webp',
  },
  {
    path: '/images/lesson-examples/roadmap-3.webp',
  },
  {
    path: '/images/lesson-examples/roadmap-4.webp',
  },
  {
    path: '/images/lesson-examples/example-4.webp',
  },
];
