import Image from 'next/image';

import { OrbitingCircles } from '@/shared/components/animation/orbiting-circles';
import { Article } from '@/shared/components/article';
import { Carousel, CarouselContent, CarouselItem } from '@/shared/components/ui/carousel';

import { EXAMPLES } from './constants/lesson-images';

export const LessonsExamples = () => {
  return (
    <Article className="relative">
      <h2 className="mb-4 header-h2 sm:mb-8 lg:mb-20">Примеры уроков</h2>

      <section className="mb-10 grid grid-cols-1 items-center gap-10 md:grid-cols-[minmax(120px,320px),1fr] lg:mb-20 lg:gap-20">
        {/* Tablet and Desktop */}
        <div className="relative mx-auto hidden w-full items-center justify-center rounded-xl bg-background p-10 lg:flex lg:h-[380px] lg:max-w-[26rem]">
          <OrbitingCircles
            className="h-[100px] w-[100px] border-none bg-transparent"
            duration={20}
            delay={20}
            radius={70}
          >
            <Image
              className="mx-2 h-auto w-auto shrink-0"
              width={100}
              height={100}
              src="/images/logos/brands/edvibe-logo.png"
              alt="Edvibe"
            />
          </OrbitingCircles>

          <OrbitingCircles
            className="h-[100px] w-[100px] border-none bg-transparent"
            duration={20}
            delay={10}
            radius={70}
          >
            <Image
              className="mx-1 h-auto w-auto shrink-0 dark:hidden"
              width={100}
              height={100}
              src="/images/logos/brands/miro-logo.png"
              alt="Miro"
            />

            <Image
              className="mx-1 hidden h-auto w-auto shrink-0 dark:block"
              width={100}
              height={100}
              src="/images/logos/brands/miro-white-logo.png"
              alt="Miro"
            />
          </OrbitingCircles>

          <OrbitingCircles className="h-[80px] w-[80px] border-none bg-transparent" reverse radius={160} duration={15}>
            <Image
              className="mx-1 h-auto w-auto shrink-0"
              width={100}
              height={100}
              priority
              src="/images/logos/brands/discord-logo.png"
              alt="Discord"
            />
          </OrbitingCircles>

          <OrbitingCircles
            className="h-[100px] w-[100px] border-none bg-transparent"
            reverse
            radius={160}
            duration={20}
            delay={30}
          >
            <Image
              className="mx-1 h-auto w-auto shrink-0"
              width={100}
              height={100}
              priority
              src="/images/logos/brands/gmeet-logo.png"
              alt="Google meet"
            />
          </OrbitingCircles>

          <OrbitingCircles
            className="h-[80px] w-[80px] border-none bg-transparent"
            reverse
            radius={160}
            duration={20}
            delay={25}
          >
            <Image
              className="mx-1 h-auto w-auto shrink-0"
              width={100}
              height={100}
              priority
              src="/images/logos/brands/zoom-logo.png"
              alt="Zoom"
            />
          </OrbitingCircles>
        </div>

        {/* Mobile */}
        <div className="relative mx-auto flex h-[240px] w-full max-w-full items-center justify-center overflow-hidden rounded-xl bg-background p-10 lg:hidden">
          <OrbitingCircles
            className="h-[70px] w-[70px] border-none bg-transparent"
            duration={20}
            delay={20}
            radius={50}
          >
            <Image
              className="mx-2 h-auto w-auto shrink-0"
              width={100}
              height={100}
              src="/images/logos/brands/edvibe-logo.png"
              alt="Edvibe"
            />
          </OrbitingCircles>

          <OrbitingCircles
            className="h-[70px] w-[70px] border-none bg-transparent"
            duration={20}
            delay={10}
            radius={50}
          >
            <Image
              className="mx-1 h-auto w-auto shrink-0 dark:hidden"
              width={100}
              height={100}
              src="/images/logos/brands/miro-logo.png"
              alt="Miro"
            />

            <Image
              className="mx-1 hidden h-auto w-auto shrink-0 dark:block"
              width={100}
              height={100}
              src="/images/logos/brands/miro-white-logo.png"
              alt="Miro"
            />
          </OrbitingCircles>

          <OrbitingCircles className="h-[70px] w-[70px] border-none bg-transparent" reverse radius={100} duration={15}>
            <Image
              className="mx-1 h-auto w-auto shrink-0"
              width={100}
              height={100}
              priority
              src="/images/logos/brands/discord-logo.png"
              alt="Discord"
            />
          </OrbitingCircles>

          <OrbitingCircles
            className="h-[70px] w-[70px] border-none bg-transparent"
            reverse
            radius={100}
            duration={20}
            delay={30}
          >
            <Image
              className="mx-1 h-auto w-auto shrink-0"
              width={100}
              height={100}
              priority
              src="/images/logos/brands/gmeet-logo.png"
              alt="Google meet"
            />
          </OrbitingCircles>

          <OrbitingCircles
            className="h-[70px] w-[70px] border-none bg-transparent"
            reverse
            radius={100}
            duration={20}
            delay={25}
          >
            <Image
              className="mx-1 h-auto w-auto shrink-0"
              width={100}
              height={100}
              priority
              src="/images/logos/brands/zoom-logo.png"
              alt="Zoom"
            />
          </OrbitingCircles>
        </div>

        <div className="space-y-6">
          <div>
            <h3 className="mb-2 header-h3">Занятия проходят на&nbsp;интерактивных платформах Edvibe и&nbsp; Miro.</h3>

            <p className="max-w-xl text-sm text-muted-foreground lg:text-base">
              Это позволяет использовать интересные материалы, хранить все задания в&nbsp;одном месте, а&nbsp;также
              в&nbsp;любой момент вернуться к&nbsp;пройденным темам.
            </p>
          </div>

          <div>
            <h3 className="mb-2 header-h3">Для связи на&nbsp;занятиях мы используем ZOOM, Google Meet или Discord.</h3>

            <p className="max-w-2xl text-sm text-muted-foreground lg:text-base">
              Выбор платформы зависит от&nbsp;ваших предпочтений. Для связи во&nbsp;внеурочное время мы&nbsp;используем
              чат в&nbsp;любом из&nbsp;удобных мессенджеров. В&nbsp;чате студенты могут в&nbsp;любое время задавать
              вопросы, обращаться за&nbsp;помощью, а&nbsp;также там происходит проверка домашних заданий.
            </p>
          </div>
        </div>
      </section>

      <section>
        <Carousel
          opts={{
            align: 'center',
            loop: true,
          }}
        >
          <CarouselContent className="min-h-[250px] sm:min-h-[300px] md:min-h-[400px]">
            {EXAMPLES.map((image, index) => {
              return (
                <CarouselItem
                  key={index}
                  className="flex justify-center sm:basis-[80%] md:basis-[90%] lg:basis-[70%] xl:basis-[60%] 2xl:basis-[50%]"
                >
                  <div className="relative w-full">
                    <Image src={image.path} className="object-contain" fill alt="Lesson example Image" />
                  </div>
                </CarouselItem>
              );
            })}
          </CarouselContent>

          <div className="pointer-events-none absolute inset-y-0 left-0 hidden h-full bg-gradient-to-r from-background xss:block sm:w-1/4"></div>
          <div className="pointer-events-none absolute inset-y-0 -right-1 hidden h-full bg-gradient-to-l from-background xss:block sm:w-1/4"></div>
        </Carousel>
      </section>
    </Article>
  );
};
