export const SERVICES_LIST = [
  {
    header: 'Групповые занятия',
    description: 'Работа в&nbsp;группе единомышленников',
    price: 1500,
    benefitsList: [
      'Практика с&nbsp;коллегами',
      'Нетворкинг',
      'Оформление резюме и&nbsp;LinkedIn в&nbsp;рамках занятий',
      'Подготовка к&nbsp;собеседованию',
      'Необходимая для работы грамматика и&nbsp;лексика',
    ],
    link: '/group-lessons',
    isServicePrimary: false,
  },
  {
    header: 'Индивидуальные занятия',
    description: 'Для тех, кто нацелен на&nbsp;быстрый результат',
    price: 2000,
    benefitsList: [
      'Гибкий график',
      'Оформление linkedin и&nbsp;подготовка к&nbsp;собеседованию',
      'Личный учебный план для достижения целей',
      'Занятия с&nbsp;русскоязычным преподавателем или с&nbsp;носителем',
    ],
    link: '/individual-lessons',
    isServicePrimary: true,
  },
  {
    header: 'Парные занятия',
    description: 'Работа в&nbsp;паре со&nbsp;специалистом из&nbsp;смежной или&nbsp;вашей сферы',
    price: 1400,
    benefitsList: [
      'Занятия в&nbsp;паре с&nbsp;коллегой того&nbsp;же уровня языка',
      'Совмещение индивидуальных и&nbsp;групповых занятий',
      'Практика кросс-интервью',
      'Практика имитации реального общения в&nbsp;рабочем процессе',
    ],
    link: '/pair-lessons',
    isServicePrimary: false,
  },
  {
    header: 'Разговорный клуб с носителем',
    description: 'Практика живого общения с&nbsp;коллегами',
    price: 1125,
    benefitsList: [
      'Тематические встречи без&nbsp;учебного плана',
      'Обсуждение актуальных тем',
      'Практика разговорной речи',
      'Telegram канал с&nbsp;анонсами',
    ],
    link: '/speaking-clubs',
    isServicePrimary: false,
  },
];
