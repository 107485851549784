import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@/shared/styles/tailwind.css';
import '@/shared/styles/globals.css';
import '@/shared/styles/nprogress.css';

import * as React from 'react';
import { GoogleAnalytics } from '@next/third-parties/google';

import { withProviders } from '@/apps/providers';

import { NetworkToast } from '@/features/network-toast';

import CookieConsent from '@/shared/components/cookie-banner/cookie-banner';
import { NextProgress } from '@/shared/components/nprogress';
import { Toaster } from '@/shared/components/ui/sonner';
import { FacebookPixelScript, YMAnalyticsScript } from '@/shared/lib/analytics';
import { GoogleTagManagerScript } from '@/shared/lib/analytics/google';
import { PageSeo } from '@/shared/lib/seo';
import { EnhancedAppProps } from '@/shared/types/enhanced-app-props';

function App(props: EnhancedAppProps) {
  const { Component, pageProps, err } = props;

  const isProd = process.env.NODE_ENV === 'production';

  const Layout = Component.Layout ?? React.Fragment;

  const faviconTags = [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png',
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    },
    {
      rel: 'mask-icon',
      href: '/safari-pinned-tab.svg',
      color: '#403c42',
    },
  ];

  const additionalMetaTags = [
    {
      name: 'msapplication-TileColor',
      content: '#603cba',
    },

    {
      name: 'google-site-verification',
      content: 'DEdr4cQrtl0wGAKxpocPyuQdCMnw8Z_MrGMgzR4TS5A',
    },
  ];

  return (
    <>
      {isProd && (
        <>
          <GoogleTagManagerScript />
          <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID} />
          <YMAnalyticsScript />
          <FacebookPixelScript />
        </>
      )}

      <PageSeo additionalLinkTags={faviconTags} additionalMetaTags={additionalMetaTags} />

      <NextProgress />

      <Layout>
        <Component {...pageProps} err={err} />
        <CookieConsent />
      </Layout>

      <NetworkToast />
      <Toaster />
    </>
  );
}

export default withProviders(App);
