import { AboutSchool } from '@/page-content/home/components/about-school';

import { MainLayout } from '@/widgets/layouts/main';
import { CallToAction } from '@/widgets/layouts/main/call-to-action';
import { SERVICES_LIST } from '@/widgets/layouts/main/constants/services-list';
import { ForWhomOurLessons } from '@/widgets/layouts/main/for-whom-our-lessons';
import { Reviews } from '@/widgets/layouts/main/reviews';
import { Services } from '@/widgets/layouts/main/services';
import { StudentsCases } from '@/widgets/layouts/main/students-cases';

import { PageSeo } from '@/shared/lib/seo';

import { LessonsExamples } from '../for-companies/components/lessons-examples';
import { WhyWorkWithUs } from './components/why-work-with-us/why-work-with-us';

export const HomePage = () => {
  return (
    <>
      <PageSeo title="Главная" description="Прокачаем ваш английский и поможем открыть новые карьерные возможности" />

      <AboutSchool />

      <ForWhomOurLessons />

      <WhyWorkWithUs />

      <LessonsExamples />

      <Services servicesList={SERVICES_LIST} />

      <StudentsCases />

      <Reviews />

      <CallToAction withForm />
    </>
  );
};

HomePage.Layout = MainLayout;
