// components/CookieConsent.tsx
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { Button } from '../ui/button';

interface CookieConsentProps {
  onConsentChange?: (consent: boolean) => void;
}

const CookieConsent = (props: CookieConsentProps) => {
  const { onConsentChange } = props;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get('cookie_consent');
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('cookie_consent', 'accepted', { expires: 365 });
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 border-t border-border/40 bg-background/95 p-4 py-4 text-white backdrop-blur supports-[backdrop-filter]:bg-background/10">
      <div className="mx-auto flex max-w-4xl flex-col items-center justify-between space-y-4 md:flex-row md:space-y-0">
        <p className="text-sm text-black dark:text-white">
          Мы&nbsp;используем куки для аналитики и&nbsp;улучшения пользовательского опыта, без них никак
        </p>
        <div className="flex space-x-2">
          <Button onClick={handleAccept} className="bg-blue-700 dark:bg-blue-600 dark:text-white">
            Все понятно
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
